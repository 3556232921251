import React from "react";
import { Link } from "gatsby";
import { Card } from "react-bootstrap";
import Img from "gatsby-image";

import styles from "./article-card.module.scss";

const ArticleCard = ({ className, image, title, text, url }) => {
  return (
    <Card className={`${styles.article} ${className}`}>
      <Link to={`/articles/${url}`}>
        <Img
          loading="eager"
          fluid={image.childImageSharp.fluid}
          placeholderStyle={{ visibility: "hidden" }}
        />
        <Card.Body className={`pt-3`}>
          <Card.Title>{title}</Card.Title>
          <Card.Text>{text}</Card.Text>
        </Card.Body>
      </Link>
    </Card>
  );
};

export default ArticleCard;
