import React from "react";
import { graphql, Link } from "gatsby";
import { Container, Breadcrumb } from "react-bootstrap";

import Layout from "../components/layout";
import ArticleCards from "../components/article-cards";

class Articles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceWidth: 0,
    };
  }
  componentDidMount() {
    if (typeof window !== "undefined") {
      this.setState({ deviceWidth: window.innerWidth });
    }
  }
  render() {
    const {
      currentPage,
      // hasNextPage,
      // hasPrevPage,
      // prevPagePath,
      // nextPagePath
    } = this.props.pageContext;
    const pageTitle =
      currentPage > 0 ? `Articles - Page ${currentPage}` : "Articles";
    // const isSmallScreen = this.state.deviceWidth < 600;
    const articles = this.props.data.articles.edges.map(
      (n) => n.node.frontmatter,
    );
    return (
      <Layout title={pageTitle}>
        <Container>
          <Breadcrumb>
            <Link to="/">Home</Link>
            <p className="pr-2 pl-2">/</p>
            <Breadcrumb.Item active>Articles</Breadcrumb.Item>
          </Breadcrumb>
          <section id="welcome">
            <Container className={`mt-5`}>
              <h1>Articles</h1>
            </Container>
          </section>
          <hr xs="12" className={`mt-3`} />

          <section id="articles" className={`mt-5`}>
            <Container>
              <ArticleCards className={`mt-3`} items={articles} />
            </Container>
          </section>
          {/* TODO: add pagination based of  prev, next, hasPrev, hasNext */}
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query ArticlesTemplate($postLimit: Int!, $postOffset: Int!) {
    articles: allMarkdownRemark(
      limit: $postLimit
      skip: $postOffset
      filter: {
        fileAbsolutePath: { regex: "/(posts)/.*.md$/" }
        frontmatter: { template: { eq: "post" } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            featured_image {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 174) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            description
            date
          }
        }
      }
    }
  }
`;

export default Articles;
