import React from "react";
import PropTypes from "prop-types";
import { CardDeck } from "react-bootstrap";

import ArticleCard from "../components/article-card";

class ArticleCards extends React.Component {
  render() {
    const { items, className } = this.props;

    const buildItem = (item, key) => (
      <ArticleCard
        key={key}
        image={item.featured_image}
        title={item.title}
        text={item.description}
        url={`${item.slug}`}
      />
    );

    return (
      <CardDeck className={className}>
        {items && items.map((item, index) => buildItem(item, index))}
      </CardDeck>
    );
  }
}

ArticleCards.propTypes = {
  items: PropTypes.array,
};

ArticleCards.defaultProps = {
  items: [],
};

export default ArticleCards;
